import L, { Marker, icon } from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'

import 'leaflet/dist/leaflet.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import iconRetinaUrl from 'url:../img/map/marker-icon-2x.png'
import iconUrl from 'url:../img/map/marker-icon.png'
import shadowUrl from 'url:../img/map/marker-shadow.png'

document.querySelector('#map-loader').onclick = function () {
  const mapNode = document.querySelector('#lage')
  mapNode.classList.add('interactive')
  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

  const map = L.map('osmmap', {
    center: [54.13834, 8.848],
    zoom: 16,
    gestureHandling: true
  })
  // Assign the imported image assets before you do anything with Leaflet.
  Marker.prototype.options.icon = icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
  })

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map)
  L.marker([54.14017849154626, 8.848371505737305]).setIcon(Marker.prototype.options.icon).addTo(map)
}
